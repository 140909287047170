import React from "react";
import ExportButton from "./ExportButton";
import toolpanel from "../../assets/theme/modules/_tool-panel.module.scss"

function ToolPanel({ exportData, disableExportButton, children }) {
  return (
    <div className={`${toolpanel.row}`}>
      <div className={`${toolpanel.item}`}>  
        {children}
      </div>
      {!disableExportButton && (
        <div className={`${toolpanel.item}`}>  
          <ExportButton exportData={exportData} />
        </div>
      )}
    </div>     
  );
}

export default ToolPanel;
