import { ClientBase } from "./ClientBase";

export class FantasyNflClientBase extends ClientBase {
  constructor() {
    super();
    this.baseUrl = this.getBaseUrl("");
  }

  /** Main fetch wrapper func used by NbcsTinyClient  */
  f(url, method, params, values) {
    return this.doFetch(url, method, params, values, false);
  }

  /** Fetch wrapper func for multipart requests  */
  fMultipart(url, method, params, values) {
    return this.doFetch(url, method, params, values, true);
  }

  doFetch(url, method, params, values, multipart) {
    // Build the URL
    let fullUrl = this.baseUrl + url;
    const query = new URLSearchParams();
    for (let i = 0; i < params.length; i++) {
      const param = params[i];
      const value = values[i];
      if (param.in === "path") {
        fullUrl = fullUrl.replace(
          "{" + param.name + "}",
          encodeURIComponent(value)
        );
      } else if (param.in === "query") {
        if (Array.isArray(value)) {
          value.forEach((item) => {
            query.append(param.name, item);
          });
        } else if (value !== undefined) {
          query.append(param.name, value);
        }
      } else {
        console.error("Unknown path 'in': " + param.in);
      }
    }

    const queryString = query.toString();
    if (!!queryString) {
      fullUrl += "?" + queryString;
    }

    // Build the fetch options and add body content if POST or PUT
    const opt = { method: method.toUpperCase(), headers: {} };
    if (method === "post" || method === "put") {
      // POST / PUT 'body' will be last element in values
      const body = values[values.length - 1];
      if (multipart) {
        // Used for file uploads etc. body should be a FormData object.
        opt.body = body;
      } else {
        opt.body = JSON.stringify(body);
        opt.headers["Content-Type"] = "application/json";
      }
    }

    // transformOptions() adds the Authorization header
    return this.transformOptions(opt)
      .then((options) => fetch(fullUrl, options))
      .then((response) => {
        switch (response.status) {
          case 401: // Unauthorized
            this.clearAccessTokenInfo();
            window.location.replace("/log-in?timeout");
            break;
          case 200: // OK
            return response
              .text()
              .then((text) =>
                Promise.resolve(text === "" ? null : JSON.parse(text))
              );
          case 204: // No Content
            return Promise.resolve(null);
          default:
            return response.text().then((text) => {
              throw new ApiException(
                "An unexpected server error occurred.",
                response.status,
                text,
                {},
                null
              );
            });
        }
      });
  }
}

// Based on NbcsClient ApiException
class ApiException extends Error {
  constructor(message, status, response, headers, result) {
    super();

    this.message = message;
    this.status = status;
    this.response = response;
    this.headers = headers;
    this.result = result;
    this.isApiException = true;
  }
}
