import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import PageNavbar from "../Navbars/PageNavbar.js";
import PageNavLinks from "../Navbars/PageNavLinks.js";
import Spinner from "../Common/Spinner";
import { Container, Row, Col } from "reactstrap";
import { client } from "../../services/FantasyNflClient.js";
import BasicTable from "../Tables/BasicTable.js";

export function AvailablePlayers() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  let { leagueId } = useParams();

  useEffect(() => {
    client
      .getAvailablePlayers(leagueId)
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [leagueId]);

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "positionSortOrder",
        Cell: ({ row }) => {
          return row.index + 1;
        },
      },
      {
        Header: "Position",
        accessor: "position",
      },
      { Header: "Name", accessor: "name" },
      {
        Header: "Team",
        accessor: "nflTeam",
        Cell: ({ value }) => {
          return !value ? (
            ""
          ) : (
            <img
              alt="..."
              src={require("../../assets/img/thumbnail/" + value + ".png")}
            ></img>
          );
        },
      },
      { Header: "Rank", accessor: "nflRank" },
      {
        Header: "ADP",
        accessor: "fantasyProsInfo.avg",
        format: ({ row }) => {
          return !row.fantasyProsInfo ? 999 : row.fantasyProsInfo.avg;
        },
      },
    ],
    []
  );

  return (
    <>
      <PageNavbar leagueId={leagueId} />
      {loading && <Spinner />}
      {!loading && (
        <>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="12">
                <div className="info">
                  <div className="description">
                    <h3 className="info-title">{data.leagueName}</h3>
                    <p className="mb-0">Available Players</p>
                  </div>
                </div>
                <PageNavLinks leagueId={leagueId} />
              </Col>
            </Row>
            <BasicTable
              columns={columns}
              data={data.players}
              disableToolPanel={true}
              disableExportButton={true}
            />
          </Container>{" "}
        </>
      )}
    </>
  );
}
