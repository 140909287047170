import React, { useState } from "react";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import popover from "../../../assets/theme/modules/_popover.module.scss";

// Based on https://codesandbox.io/s/github/tannerlinsley/react-table/tree/v7/examples/filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
  closePopover,
}) {
  const [val, setVal] = useState(filterValue || "");

  return (
    <div className={`${popover.popover_content} ${popover.popover_input}`}>
      <input
        type="input"
        className="form-control"
        value={val}
        onChange={(e) => setVal(e.target.value)}
        placeholder="Type value"
      />
      <button
        type="button"
        className="btn btn-info ml-2"
        disabled={!val}
        onClick={() => {
          setFilter(val || undefined);
          closePopover();
        }}
      >
        {/* <FontAwesomeIcon icon={faChevronRight} size="sm" /> */}
      </button>
    </div>
  );
}

export default DefaultColumnFilter;
