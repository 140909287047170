import {
  getLocalStorageInfo,
  setLocalStorageInfo,
  clearLocalStorageInfo,
} from "./LocalStorageUtility";

import config from "./config.json";

interface IAccessTokenInfo {
  accessToken: string;
  refreshToken: string;
  accessTokenExpiration: number;
  refreshTokenExpiration: number;
  serverTime: number;
  emailAddress: string;
}

export class ClientBase {
  private _accessToken = "";
  private _accessTokenExpiresAt = new Date();
  private _defaultUrl: string;
  private _refreshAccessTokenUrl: string;

  // This can be overridden by other ClientBase classes
  getApiUrlsConfigKey() {
    return "nbcsClientUrls";
  }

  constructor() {
    const host = window.location.hostname;
    var configDict = config as Record<string, Record<string, string>>;
    const apiUrls = configDict[this.getApiUrlsConfigKey()];
    this._defaultUrl = apiUrls[host];
    if (!this._defaultUrl) {
      alert(`No apiUrl specified in config.json for hostname '${host}'`);
    }

    // The refresh token always comes from nbcsClient
    const nbcsClientUrls = configDict["nbcsClientUrls"];
    this._refreshAccessTokenUrl = nbcsClientUrls[host] + "/api/token/refresh";
  }

  getBaseUrl(defaultUrl: string, baseUrl: string | undefined) {
    return this._defaultUrl;
  }

  private toExpirationDate(tokenExpiration: number, serverTime: number) {
    const tokenDurationMilliseconds = (tokenExpiration - serverTime) / 10000;
    const skewMilliseconds = 10 * 1000;
    return new Date(
      new Date().getTime() + tokenDurationMilliseconds - skewMilliseconds
    );
  }

  setAccessTokenInfo(accessTokenInfo: IAccessTokenInfo) {
    this._accessToken = accessTokenInfo.accessToken;
    this._accessTokenExpiresAt = this.toExpirationDate(
      accessTokenInfo.accessTokenExpiration,
      accessTokenInfo.serverTime
    );

    const refreshTokenExpiresAt = this.toExpirationDate(
      accessTokenInfo.refreshTokenExpiration,
      accessTokenInfo.serverTime
    );

    setLocalStorageInfo({ ...accessTokenInfo, refreshTokenExpiresAt });
  }

  clearAccessTokenOnly() {
    this._accessToken = "";
  }

  clearAccessTokenInfo() {
    this._accessToken = "";
    clearLocalStorageInfo();
  }

  getEmailAddress() {
    const info = getLocalStorageInfo();
    if (!info) return "";
    return info.emailAddress;
  }

  getRefreshTokenExpiresAt() {
    const info = getLocalStorageInfo();
    if (!info) return new Date(0);
    return info.refreshTokenExpiresAt;
  }

  isLoggedIn() {
    const info = getLocalStorageInfo();
    if (!info) return false;
    return info.refreshTokenExpiresAt > new Date();
  }

  async refreshAccessToken(
    refreshToken: string,
    emailAddress: string
  ): Promise<IAccessTokenInfo | undefined> {
    const content = JSON.stringify({
      token: refreshToken,
      userEmail: emailAddress,
    });

    const options = {
      body: content,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
      },
    };

    const response = await fetch(this._refreshAccessTokenUrl, options);
    if (response.status === 200) {
      const data = await response.json();
      return { ...data, emailAddress };
    }

    return undefined;
  }

  async getAccessToken(): Promise<string | undefined> {
    if (this._accessToken && this._accessTokenExpiresAt > new Date()) {
      return this._accessToken;
    }

    const info = getLocalStorageInfo();
    const refreshTokenValid = !!info && info.refreshTokenExpiresAt > new Date();
    if (refreshTokenValid) {
      try {
        const accessTokenInfo = await this.refreshAccessToken(
          info?.refreshToken ?? "",
          info?.emailAddress ?? ""
        );

        if (accessTokenInfo) {
          this.setAccessTokenInfo(accessTokenInfo);
          return accessTokenInfo.accessToken;
        }
      } catch (error) {
        console.log("Error refreshing accessToken", error);
      }
    }

    this.clearAccessTokenInfo();
    return undefined;
  }

  // Here we can make changes to the fetch() headers
  async transformOptions(options: RequestInit): Promise<RequestInit> {
    const accessToken = await this.getAccessToken();
    if (accessToken && options.headers) {
      const headers = options.headers as Record<string, string>;
      headers["Authorization"] = "Bearer " + accessToken;
    }
    return options;
  }

  transformResult(
    url: string,
    response: Response,
    processor: (response: Response) => Promise<any>
  ): Promise<any> {
    if (response.status === 401) {
      // Redirect to login on any unauthorized exception
      this.clearAccessTokenInfo();
      window.location.replace("/log-in?timeout");
    }

    return processor(response);
  }
}
