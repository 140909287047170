import React from "react";
import LandingPageHeader from "../Headers/LandingPageHeader.js";
import HomeNavbar from "../Navbars/HomeNavbar.js";

export function NflHome() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <HomeNavbar />
      <LandingPageHeader />
      <div className="wrapper"></div>
    </>
  );
}
