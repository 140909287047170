/*eslint-disable*/
import React from "react";

function Footer() {
  return (
    <>
      <footer className="footer footer-black">
        <div className="container">
          <div className="row">
            <div className="credits ml-auto">
              <span className="copyright">
                © {new Date().getFullYear()} MDKENNEDY LTD
              </span>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
