import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Popover, PopoverBody } from "reactstrap";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faChevronUp, faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import popover from "../../../assets/theme/modules/_popover.module.scss";

function ColumnFilterPopover({ column, currentFilter, setCurrentFilter }) {
  const buttonRef = useRef(null);
  const open = useMemo(
    () => currentFilter === column.id,
    [currentFilter, column.id]
  );

  const toggle = useCallback(() => {
    setCurrentFilter(open ? "" : column.id);
  }, [setCurrentFilter, open, column.id]);

  // Setup body click listener to close the popover when clicking outside it
  useEffect(() => {
    // Don't care if not open
    if (!open) return;

    const handleClick = (event) => {
      // Ignore when clicking within the button
      const isButtonClick = buttonRef.current.contains(event.target);
      if (isButtonClick) return;

      // Ignore when clicking within the popover
      const isPopoverClick = [
        ...document.getElementsByClassName("column-filter-" + column.id),
      ].some((popover) => popover.contains(event.target));
      if (isPopoverClick) return;

      // toggle
      setCurrentFilter(open ? "" : column.id);
    };

    document.body.addEventListener("click", handleClick);
    return () => {
      document.body.removeEventListener("click", handleClick);
    };
  }, [open, column.id, setCurrentFilter, toggle]);

if (!column.canFilter) return <></>;

  return (
    <>
      <button
        type="button"
        className="btn btn-link"
        onClick={toggle}
        ref={buttonRef}
      >
       {/*  {open ? (
          <FontAwesomeIcon icon={faChevronUp} size="xs" />
        ) : (
          <FontAwesomeIcon icon={faChevronDown} size="xs" />
        )} */}
      </button>
      {buttonRef.current && (
        <Popover
          placement="bottom-start"
          isOpen={open}
          target={buttonRef}
          toggle={toggle}
          hideArrow={true}
          className={"column-filter column-filter-" + column.id}
          offset={[-10,4]}
          popperClassName={popover.popover}
        >
          <PopoverBody className={popover.popover_body}>
            {column.render("Filter", { closePopover: toggle })}
          </PopoverBody>
        </Popover>
      )}
    </>
  );
}

export default ColumnFilterPopover;
