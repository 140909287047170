import React from "react";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faTimes } from "@fortawesome/pro-regular-svg-icons";
//import toolpanel from "../../../../BootstrapThemes/flatly/modules/_tool-panel.module.scss";

function enrichFilter(filter, columns) {
  const column = columns.find((c) =>
    typeof c.accessor === "function"
      ? c.id === filter.id
      : c.accessor === filter.id
  );
  return {
    ...filter,
    Header: column.Header,
    // Custom property to format custom filter values for display. Function(value) => string
    filterFormatter: column.filterFormatter,
  };
}

function FilterPanel({ filters, columns, clearFilter, clearAllFilters }) {
  //  Don't show when no filters
  if (filters.length === 0) return <></>;

  return (<></>
    /* 
    <div className={`${toolpanel.row}`}>
      <div className={`${toolpanel.item}`}>
        <div className={`${toolpanel.filters_heading}`}>Selected filters:</div>

        <ul className={`${toolpanel.filters}`}>
          {filters.map((filter) => (
            <FilterItem
              key={filter.id}
              enrichedFilter={enrichFilter(filter, columns)}
              clearFilter={clearFilter}
            />
          ))}
        </ul>
      </div>
      {filters.length > 0 && (
        <div className={`${toolpanel.item}`}>
          <button onClick={clearAllFilters} className="btn btn-link">
            <i>
            </i>
            <span className="ml-2">Clear&nbsp;all</span>
          </button>
        </div>
      )}
    </div> */
  );
}

function FilterItem({ enrichedFilter, clearFilter }) {
  return ( <></>
    /* {<li
      className={`${toolpanel.filter}`}
      onClick={() => clearFilter(enrichedFilter.id)}
    >
      <span className={`${toolpanel.filter_type}`}>
        {enrichedFilter.Header}:
      </span>
      <span className={`${toolpanel.filter_value}`}>
        {enrichedFilter.filterFormatter
          ? enrichedFilter.filterFormatter(enrichedFilter.value)
          : enrichedFilter.value}
      </span>
    </li> }*/
  );
}

export default FilterPanel;
