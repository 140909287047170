import React, { useMemo } from "react";
import DataTable from "react-data-table-component";

import { Col, Card, CardBody, CardTitle } from "reactstrap";

export function FinalStandings({ leagueId, divisionName, teams }) {

  const columns = useMemo(
    () => [
      {
        name: "Final Position",
        selector: "finalPosition",
        sortable: true,
        width: "100px",
      },
      {
        name: "",
        selector: "avatar",
        sortable: false,
        width: "100px",
        format: (row) => {
          //eslint-disable-next-line no-useless-concat
          return (
            <div className="card-avatar border-white">
              <a>
                <img height={25} width={25} src={row.avatar} alt="..." />
              </a>
            </div>
          );
        },
      },
      {
        name: "Team",
        selector: "teamName",
        sortable: false,
        width: "250px",
      },
      {
        name: "Regular Season",
        selector: "wins",
        sortable: false,
        format: (row) => {
          //eslint-disable-next-line no-useless-concat
          return `Reg. Season: ${row.wins}-${row.losses}-${row.draws}  /  ${row.pointsFor} Points For`;
        },
      },
    ],
    []
  );

  return (
    <>
      <Col md="12">
        <Card className="card-product card-plain">
          <div className="card-image">
            <CardBody>
              <div className="card-description">
                <CardTitle tag="h5">{divisionName}</CardTitle>
              </div>
              <DataTable
                columns={columns}
                dense={true}
                data={teams}
                striped={true}
                noHeader={true}
                defaultSortAsc={true}
                defaultSortField={"finalPosition"}
                fixedHeader={true}
                pointerOnHover={true}
              />
            </CardBody>
          </div>
        </Card>
      </Col>
    </>
  );
}
