import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { client } from "../../services/FantasyNflClient.js";

export function LeagueSettings() {
  let { leagueId } = useParams();

  useEffect(() => {
    if (leagueId) {
      client
        .settingsGet(leagueId)
        .then((response) => {
          window.$leagueSettings = response;
        })
        .catch((error) => console.log(error));
    }
  }, []);

  return <></>;
}

export function hasLeagueDrafted() {
    if (window.$leagueSettings) {
     return  window.$leagueSettings["hasDrafted"]
    }
    
    return false;
};