import React from "react";
import { RouteComponentProps } from "react-router-dom";
import PageNavbar from "../Navbars/PageNavbar.js";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import PageNavLinks from "../Navbars/PageNavLinks";

import { Container, Row, Col, Button } from "reactstrap";
import { client } from "../../services/FantasyNflClient.js";

export interface ILeagueProps
  extends RouteComponentProps<{
    leagueId: string;
  }> {}

export interface ILeagueKeeperBreakdown {
  teamName: string;
  count: number;
  avgAge: number;
  avgAdp: number;
  qbCount: number;
  avgQbAge: number;
  avgQbAdp: number;
  rbCount: number;
  avgRbAge: number;
  avgRbAdp: number;
  wrCount: number;
  avgWrAge: number;
  avgWrAdp: number;
  teCount: number;
  avgTeAge: number;
  avgTeAdp: number;
}
export interface IKeeperBreakdownState {
  leagueName: string;
  teams: ILeagueKeeperBreakdown[];
  adpValuesSelected: boolean;
}

export class KeeperBreakdown extends React.Component<
  ILeagueProps,
  IKeeperBreakdownState
> {
  constructor(props: ILeagueProps, state: IKeeperBreakdownState) {
    super(props, state);
    this.state = {
      leagueName: "",
      teams: [],
      adpValuesSelected: true,
    };
  }

  async populateKeeperBreakdownData() {
    await client
      .getKeeperBreakdown(this.props.match.params.leagueId)
      .then((r) => {
        this.setState({
          leagueName: r.leagueName,
          teams: r.teams,
          adpValuesSelected: true,
        });
      })
      .catch((e) => {});
  }

  componentDidMount = async (): Promise<void> => {
    this.populateKeeperBreakdownData();
  };

  adpColumns: IDataTableColumn<ILeagueKeeperBreakdown>[] = [
    {
      name: "Team",
      selector: "teamName",
      sortable: true,
      width: "140px",
    },
    {
      name: "Keepers",
      selector: "count",
      sortable: true,
      center: true,
      width: "99px",
    },
    {
      name: "ADP",
      selector: "avgAdp",
      sortable: true,
      center: true,
      width: "99px",
    },
    {
      name: "QB",
      selector: "qbCount",
      sortable: true,
      center: true,
      width: "99px",
    },
    {
      name: "QB ADP",
      selector: "avgQbAdp",
      sortable: true,
      center: true,
      width: "99px",
    },
    {
      name: "RB",
      selector: "rbCount",
      sortable: true,
      center: true,
      width: "99px",
    },
    {
      name: "RB ADP",
      selector: "avgRbAdp",
      sortable: true,
      center: true,
      width: "99px",
    },
    {
      name: "WR",
      selector: "wrCount",
      sortable: true,
      center: true,
      width: "99px",
    },
    {
      name: "WR ADP",
      selector: "avgWrAdp",
      sortable: true,
      center: true,
      width: "99px",
    },
    {
      name: "TE",
      selector: "teCount",
      sortable: true,
      center: true,
      width: "99px",
    },
    {
      name: "TE ADP",
      selector: "avgTeAdp",
      sortable: true,
      center: true,
      width: "99px",
    },
  ];

  ageColumns: IDataTableColumn<ILeagueKeeperBreakdown>[] = [
    {
      name: "Team",
      selector: "teamName",
      sortable: true,
      width: "140px",
    },
    {
      name: "Keepers",
      selector: "count",
      sortable: true,
      center: true,
      width: "99px",
    },
    {
      name: "Avg Age",
      selector: "avgAge",
      sortable: true,
      center: true,
      width: "99px",
    },
    {
      name: "QB",
      selector: "qbCount",
      sortable: true,
      center: true,
      width: "99px",
    },
    {
      name: "QB Age",
      selector: "avgQbAge",
      sortable: true,
      center: true,
      width: "99px",
    },
    {
      name: "RB",
      selector: "rbCount",
      sortable: true,
      center: true,
      width: "99px",
    },
    {
      name: "RB Age",
      selector: "avgRbAge",
      sortable: true,
      center: true,
      width: "99px",
    },
    {
      name: "WR",
      selector: "wrCount",
      sortable: true,
      center: true,
      width: "99px",
    },
    {
      name: "WR Age",
      selector: "avgWrAge",
      sortable: true,
      center: true,
      width: "99px",
    },
    {
      name: "TE",
      selector: "teCount",
      sortable: true,
      center: true,
      width: "99px",
    },
    {
      name: "TE Age",
      selector: "avgTeAge",
      sortable: true,
      center: true,
      width: "99px",
    },
  ];

  get getColumns() {
    if (this.state.adpValuesSelected) {
      return this.adpColumns;
    } else {
      return this.ageColumns;
    }
  }

  setColumns(value: boolean) {
    this.setState({ adpValuesSelected: value });
  }

  render() {
    return (
      <>
        <PageNavbar leagueId={this.props.match.params.leagueId} />

        <div className="wrapper">
          <div className="section latest-offers">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="12">
                  <div className="info">
                    <div className="description">
                      <h3 className="info-title">{this.state.leagueName}</h3>
                      <p className="mb-0">Standings</p>
                    </div>
                  </div>
                  <PageNavLinks leagueId={this.props.match.params.leagueId} />
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Button
                  onClick={() => this.setColumns(true)}
                  className="btn-round"
                  color="info"
                >
                  Adp
                </Button>
                <Button
                  onClick={() => this.setColumns(false)}
                  className="btn-round"
                  color="danger"
                >
                  Age
                </Button>
              </Row>
              <Row>
                <DataTable
                  columns={this.getColumns}
                  dense={true}
                  data={this.state.teams}
                  striped={true}
                  noHeader={true}
                  defaultSortField={"positionSortOrder"}
                  fixedHeader={true}
                  pointerOnHover={true}
                />
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }
}
