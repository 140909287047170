type LocalStorageInfo = {
  refreshToken: string;
  refreshTokenExpiresAt: Date;
  emailAddress: string;
};

function getDateFromTimeText(text: string | null) {
  if (!text) return null;
  const num = Number(text);
  if (isNaN(num)) return null;
  return new Date(num);
}

export function getLocalStorageInfo() {
  const refreshToken = localStorage.getItem("refreshToken");
  const refreshTokenExpiresAt = getDateFromTimeText(
    localStorage.getItem("refreshTokenExpiresAt")
  );
  const emailAddress = localStorage.getItem("emailAddress");
  if (!refreshToken || !refreshTokenExpiresAt || !emailAddress) return null;

  return {
    refreshToken,
    refreshTokenExpiresAt,
    emailAddress,
  } as LocalStorageInfo;
}

export function setLocalStorageInfo(info: LocalStorageInfo) {
  localStorage.setItem("refreshToken", info.refreshToken);
  localStorage.setItem(
    "refreshTokenExpiresAt",
    info.refreshTokenExpiresAt.getTime().toString()
  );
  localStorage.setItem("emailAddress", info.emailAddress);
}

export function clearLocalStorageInfo() {
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("refreshTokenExpiresAt");
  localStorage.removeItem("emailAddress");
}
