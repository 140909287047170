import React, { useState } from "react";
import { useTable, useFilters, useSortBy, usePagination } from "react-table";
import { useExportData } from "react-table-plugins";
import matchSorter from "match-sorter";
import Paginate from "./Paginate";
import ColumnFilterPopover from "./filters/ColumnFilterPopover.js";
import DefaultColumnFilter from "./filters/DefaultColumnFilter";
import FilterPanel from "./FilterPanel";
import { getExportFileBlob } from "./ExportButton";
import ToolPanel from "./ToolPanel";

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Based on original BasicTable
// See https://react-table-v7.tanstack.com/ for react-table docs
// See https://github.com/gargroh/react-table-plugins/blob/HEAD/docs/useExportData.md for export docs
function BasicTable({
  columns,
  data,
  toolPanelChildren,
  disableToolPanel,
  disableExportButton,
  noDataMessage,
  hidePagination,
  hideHeader,
  autoResetFilters = true,
  getAdditionalRowProps = null,
}) {
  const initialPageSize = 20;

  // currentFilter used to make sure only 1 filter popover is open at a time
  const [currentFilter, setCurrentFilter] = useState("");

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    state: { pageSize, filters },
    gotoPage,
    setPageSize,
    setFilter,
    setAllFilters,
    exportData,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { pageSize: initialPageSize, pageIndex: 0 },
      getExportFileBlob,
      getExportFileName: () => "all-data",
      autoResetFilters: autoResetFilters,
    },
    useFilters,
    useSortBy,
    usePagination,
    useExportData
  );

  if (data.length === 0) return <h4>{noDataMessage ?? "No data available"}</h4>;

  return (
    <div className="table-wrapper">
      {!disableToolPanel && (
        <ToolPanel
          exportData={exportData}
          disableExportButton={disableExportButton}
        >
          {toolPanelChildren}
        </ToolPanel>
      )}
{/*       <FilterPanel
        filters={filters}
        columns={columns}
        clearFilter={(id) => setFilter(id, undefined)}
        clearAllFilters={() => setAllFilters([])}
      /> */}
      <div className="table-responsive">
        <table
          {...getTableProps()}
          className="table table-striped table-outline"
        >
          {!hideHeader && (
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      <div className="table-filter">
                        <ColumnFilterPopover
                          column={column}
                          currentFilter={currentFilter}
                          setCurrentFilter={setCurrentFilter}
                        />
                        <span {...column.getSortByToggleProps()}>
                          {column.render("Header")}
                        </span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
          )}
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              const rowProps = getAdditionalRowProps != null ? getAdditionalRowProps(row) : {};
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} {...rowProps}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {page.length === 0 ? (
        <div className="no-results">
          No results found. Adjust filters to find results
        </div>
      ) : (
        <Paginate
          hidePagination={hidePagination}
          pageSize={pageSize}
          maxPageSize={data.length}
          onChangePageSize={(size) => setPageSize(Number(size))}
          pageCount={pageCount}
          onPageChange={(page) => gotoPage(page)}
          initialPageSelected={0}
        />
      )}
    </div>
  );
}

export default BasicTable;
