import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import PageNavbar from "../Navbars/PageNavbar.js";
import PageNavLinks from "../Navbars/PageNavLinks.js";
import DataTable from "react-data-table-component";

import { Container, Row, Col } from "reactstrap";
import { client } from "../../services/FantasyNflClient.js";
import Spinner from "../Common/Spinner.js";
import { formatDate } from "../../services/dateUtlity.js";

export function DraftOrder() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  let { leagueId } = useParams();

  useEffect(() => {
    client
      .getDraftOrder(leagueId)
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [leagueId]);

  const columns = useMemo(
    () => [
      {
        name: "Pick No",
        selector: "pickString",
        sortable: false,
        conditionalCellStyles: [
          {
            when: (row) => row.keeper,
            style: {
              backgroundColor: "#FBBAAF",
              color: "white",
            },
          },
        ],
      },
      {
        name: "Total Pick",
        selector: "totalPick",
        sortable: false,
        conditionalCellStyles: [
          {
            when: (row) => row.keeper,
            style: {
              backgroundColor: "#FBBAAF",
              color: "white",
            },
          },
        ],
      } /* 
      {
        name: "Actual Pick",
        selector: "totalPickIncKeepers",
        sortable: false,
        format: (row) => {
          return row.totalPickIncKeepers > 0 ? row.totalPickIncKeepers : "-";
        },
      }, */,
      {
        name: "Team Name",
        selector: "teamName",
        sortable: false,
        conditionalCellStyles: [
          {
            when: (row) => row.keeper,
            style: {
              backgroundColor: "#FBBAAF",
              color: "white",
            },
          },
        ],
      },
      {
        name: "Traded From",
        selector: "originalTeam",
        sortable: false,
        conditionalCellStyles: [
          {
            when: (row) => row.keeper,
            style: {
              backgroundColor: "#FBBAAF",
              color: "white",
            },
          },
        ],
      },
      {
        name: "Traded On",
        selector: "originalDate",
        sortable: false,
        conditionalCellStyles: [
          {
            when: (row) => row.keeper,
            style: {
              backgroundColor: "#FBBAAF",
              color: "white",
            },
          },
        ],
      },
      {
        name: "Keeper",
        selector: "keeper",
        sortable: false,
        conditionalCellStyles: [
          {
            when: (row) => row.keeper,
            style: {
              backgroundColor: "#FBBAAF",
              color: "white",
            },
          },
        ],
      },
    ],
    []
  );

  return (
    <>
      <PageNavbar leagueId={leagueId} />
      {loading && <Spinner />}
      {!loading && (
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="12">
                <div className="info">
                  <div className="description">
                    <h3 className="info-title">{data.leagueName}</h3>
                    <p className="mb-0">Draft Order</p>
                    {data.lastDraftOrderRefresh && (
                      <small>Last Refresh: {formatDate(data.lastDraftOrderRefresh, "YYYY-MM-DD HH:mm")}</small>
                    )}
                  </div>
                </div>
                <PageNavLinks leagueId={leagueId} />
              </Col>
            </Row>
            <Row>
              {data.rounds.map((round) => (
                <DataTable
                  key={`round_${round}`}
                  className="mb-5"
                  columns={columns}
                  dense={true}
                  data={round.picks}
                  striped={true}
                  noHeader={true}
                  fixedHeader={true}
                  pointerOnHover={true}
                />
              ))}
            </Row>
          </Container>
      )}
    </>
  );
}
