import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import history from "./history";
import registerServiceWorker from "./registerServiceWorker";
import swConfig from "./swConfig";
import { App } from "./App";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

ReactDOM.render(
  <Router history={history} basename={baseUrl}>
    <App />
  </Router>,
  rootElement
);

registerServiceWorker(swConfig);
