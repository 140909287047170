import React from "react";
import Footer from "./Common/Footer.js";

export function Layout(props) {
  return (
    <div className="wrapper">
      <div className="section latest-offers">
        {props.children}
        <Footer />
      </div>
    </div>
  );
}
