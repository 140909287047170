import React from "react";
import { unparse } from "papaparse";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faFileExport } from "@fortawesome/pro-regular-svg-icons";

// See https://github.com/gargroh/react-table-plugins/blob/HEAD/docs/useExportData.md
export function getExportFileBlob({ columns, data, fileType, fileName }) {
  // Export CSV
  const headerNames = columns.map((col) => col.exportValue);
  const csvString = unparse({
    fields: headerNames,
    data: data.map(escapeCsvInjectionAttackChars),
  });
  return new Blob([csvString], { type: "text/csv" });
}

function escapeCsvInjectionAttackChars(row) {
  const escapeChar = "_";
  return row.map((cell) =>
    cell?.length > 1 &&
    (cell.startsWith("=") ||
      cell.startsWith("+") ||
      cell.startsWith("-") ||
      cell.startsWith("@"))
      ? escapeChar + cell
      : cell
  );
}

function ExportButton({ exportData, className = null }) {
  return (
    <button
      onClick={() => exportData("csv", true)}
      className={className ? className : "btn btn-link"} 
    >
      <i>
        {/* <FontAwesomeIcon icon={faFileExport} size="sm" /> */}
        </i>
      <span className="ml-2">Export results</span>
    </button>
  );
}

export default ExportButton;
