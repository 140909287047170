import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import PageNavbar from "../Navbars/PageNavbar.js";
import PageNavLinks from "../Navbars/PageNavLinks.js";
import { Container, Row, Col } from "reactstrap";
import { client } from "../../services/FantasyNflClient.js";
import DataTable from "react-data-table-component";
import classNames from "classnames";
import Spinner from "../Common/Spinner.js";

export function RookiesByYear() {
  const [loading, setLoading] = useState(true);
  const [rookies, setRookies] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  let filteredRookies = () => {
    if (activeTab !== "") {
      return rookies.filter((r) => r.position === activeTab);
    }
    return rookies;
  };
  let { leagueId, year } = useParams();

  useEffect(() => {
    client
      .rookiesByYear(year)
      .then((data) => {
        setRookies(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [year]);

  const columns = useMemo(
    () => [
      {
        name: "Player Name",
        selector: "name",
        sortable: true,
      },
      {
        name: "Position",
        selector: "position",
        sortable: true,
      },
      {
        name: "Weight (Lbs)",
        selector: "weightLbs",
        sortable: true,
      },
      {
        name: "Height",
        selector: "height",
        sortable: true,
      },
      {
        name: "40 Yard",
        selector: "yardDash",
        sortable: true,
      },
      {
        name: "FPros Pos Rank",
        selector: "fantasyProsRank",
        sortable: true,
      },
      {
        name: "DraftBuzz Pos Rank",
        selector: "draftBuzzRank",
        sortable: true,
      },
    ],
    []
  );

  return (
    <>
      <PageNavbar leagueId={leagueId} />
      {loading && <Spinner />}
      {!loading && (
        <>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="12">
                <div className="info">
                  <div className="description">
                    <h3 className="info-title">Rookies</h3>
                  </div>
                </div>
                <PageNavLinks leagueId={leagueId} />
              </Col>
            </Row>
            <div className="nav-tabs-navigation" style={{ marginBottom: 0 }}>
              <div className="nav-tabs-wrapper">
                <ul id="tabs" className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <a
                      className={classNames("nav-link", {
                        active: activeTab === "",
                      })}
                      data-toggle="tab"
                      role="tab"
                      onClick={() => setActiveTab("")}
                    >
                      All
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={classNames("nav-link", {
                        active: activeTab === "QB",
                      })}
                      data-toggle="tab"
                      role="tab"
                      onClick={() => setActiveTab("QB")}
                    >
                      QB
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={classNames("nav-link", {
                        active: activeTab === "RB",
                      })}
                      data-toggle="tab"
                      role="tab"
                      onClick={() => setActiveTab("RB")}
                    >
                      RB
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={classNames("nav-link", {
                        active: activeTab === "WR",
                      })}
                      data-toggle="tab"
                      role="tab"
                      onClick={() => setActiveTab("WR")}
                    >
                      WR
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={classNames("nav-link", {
                        active: activeTab === "TE",
                      })}
                      data-toggle="tab"
                      role="tab"
                      onClick={() => setActiveTab("TE")}
                    >
                      TE
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <Row>
              <DataTable
                columns={columns}
                dense={true}
                data={filteredRookies()}
                striped={true}
                defaultSortField={"index"}
                noHeader={false}
                fixedHeader={true}
                pointerOnHover={true}
              />
            </Row>
          </Container>
        </>
      )}
    </>
  );
}
