/*eslint-disable*/
import React from "react";

export default function Spinner() {
  return (
    <div className="position-relative w-100 h-100 d-flex flex-column align-items-center bg-white justify-content-center">
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}
