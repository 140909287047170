import React, { useState, useEffect, useMemo } from "react";
import { useParams, Link } from "react-router-dom";
import PageNavbar from "../Navbars/PageNavbar.js";
import PageNavLinks from "../Navbars/PageNavLinks.js";
import DataTable from "react-data-table-component";

import { Container, Row, Col } from "reactstrap";
import { client } from "../../services/FantasyNflClient.js";
import Spinner from "../Common/Spinner.js";

export function PointsByYearTeam() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  let { leagueId, year, teamId } = useParams();

  useEffect(() => {
    client
      .pointsByYearTeam(leagueId, year, teamId)
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [leagueId, year, teamId]);

  const columns = useMemo(
    () => [
      {
        name: "Week",
        selector: "week",
        sortable: true,
        format: (row) => {
          //eslint-disable-next-line no-useless-concat
          return (
            <Link
              to={{
                pathname:
                  "/history/" +
                  `${leagueId}` +
                  "/pointsbyyear/" +
                  `${year}` +
                  `/${teamId}` +
                  `/${row.week}`,
                state: { data: row },
              }}
            >
              {" "}
              {row.week}
            </Link>
          );
        },
      },
      {
        name: "Team Score",
        selector: "teamScoreTotal",
        sortable: true,
      },
      {
        name: "Bench Score",
        selector: "benchScoreTotal",
        sortable: true,
      },
      { name: "Res Score", selector: "resScoreTotal", sortable: true },
      {
        name: "Total Score",
        selector: "teamScoreIncBenchAndRes",
        sortable: true,
      },
    ],
    []
  );

  return (
    <>
      <PageNavbar leagueId={leagueId} />
      {loading && <Spinner />}
      {!loading && (
        <>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="12">
                <div className="info">
                  <div className="description">
                    <h3 className="info-title">{data.weeks[0]?.teamName}</h3>
                    <p className="mb-0">{year} Weekly Scores</p>
                  </div>
                </div>
                <PageNavLinks leagueId={leagueId} />
              </Col>
            </Row>
            <Row>
              <DataTable
                columns={columns}
                dense={true}
                data={data.weeks}
                striped={true}
                noHeader={false}
                defaultSortField={"week"}
                fixedHeader={true}
                pointerOnHover={true}
              />
            </Row>
          </Container>
        </>
      )}
    </>
  );
}
