import { FantasyNflClientBase } from "./FantasyNflClientBase"

export class FantasyNflClient extends FantasyNflClientBase {
    populateCache() { return this.f("/api/Admin/populateCache", "get", [], []); }

    populateRookies() { return this.f("/api/Admin/populateRookies", "get", [], []); }

    populateFullLeagueYearData() { return this.f("/api/Admin/PopulateFullLeagueYearData", "get", [], []); }

    populateLastSeasonsRoster(leagueId) { return this.f("/api/Admin/PopulateLastSeasonsRoster", "get", [{"name":"leagueId","in":"query"}], [leagueId]); }

    refreshKeepers(leagueId) { return this.f("/api/Admin/RefreshKeepers", "get", [{"name":"leagueId","in":"query"}], [leagueId]); }

    refreshRosters(leagueId) { return this.f("/api/Admin/RefreshRosters", "get", [{"name":"leagueId","in":"query"}], [leagueId]); }

    refreshStandings(leagueId) { return this.f("/api/Admin/RefreshStandings", "get", [{"name":"leagueId","in":"query"}], [leagueId]); }

    updateLeagueYearData() { return this.f("/api/Admin/UpdateLeagueYearData", "get", [], []); }

    getNflPlayerRankings() { return this.f("/api/Admin/GetNflPlayerRankings", "get", [], []); }

    setFantasyProsPlayerIds() { return this.f("/api/Admin/SetFantasyProsPlayerIds", "get", [], []); }

    loadNewFantasyProsFile() { return this.f("/api/Admin/loadNewFantasyProsFile", "get", [], []); }

    updateHistoricalTeamWeekData(leagueId) { return this.f("/api/Admin/updateHistoricalTeamWeekData", "get", [{"name":"leagueId","in":"query"}], [leagueId]); }

    populateLeagueDraftOrder(leagueId) { return this.f("/api/Admin/populateLeagueDraftOrder", "get", [{"name":"leagueId","in":"query"}], [leagueId]); }

    getDraftOrder(leagueId) { return this.f("/api/Draft/GetDraftOrder", "get", [{"name":"leagueId","in":"query"}], [leagueId]); }

    pointsByYear(leagueId, year) { return this.f("/api/History/PointsByYear", "get", [{"name":"leagueId","in":"query"},{"name":"year","in":"query"}], [leagueId, year]); }

    pointsByYearTeam(leagueId, year, teamId) { return this.f("/api/History/PointsByYearTeam", "get", [{"name":"leagueId","in":"query"},{"name":"year","in":"query"},{"name":"teamId","in":"query"}], [leagueId, year, teamId]); }

    pointsByYearTeamWeek(leagueId, year, teamId, week) { return this.f("/api/History/PointsByYearTeamWeek", "get", [{"name":"leagueId","in":"query"},{"name":"year","in":"query"},{"name":"teamId","in":"query"},{"name":"week","in":"query"}], [leagueId, year, teamId, week]); }

    getLeague(leagueId) { return this.f("/api/League/GetLeague", "get", [{"name":"leagueId","in":"query"}], [leagueId]); }

    getDepthChart(leagueId) { return this.f("/api/League/GetDepthChart", "get", [{"name":"leagueId","in":"query"}], [leagueId]); }

    getKeeperBreakdown(leagueId) { return this.f("/api/League/GetKeeperBreakdown", "get", [{"name":"leagueId","in":"query"}], [leagueId]); }

    getRosterBreakdown(leagueId) { return this.f("/api/League/GetRosterBreakdown", "get", [{"name":"leagueId","in":"query"}], [leagueId]); }

    getLeagueYears(leagueId) { return this.f("/api/League/GetLeagueYears", "get", [{"name":"leagueId","in":"query"}], [leagueId]); }

    getAvailablePlayers(leagueId) { return this.f("/api/League/GetAvailablePlayers", "get", [{"name":"leagueId","in":"query"}], [leagueId]); }

    rookiesByYear(year) { return this.f("/api/Rookies", "get", [{"name":"year","in":"query"}], [year]); }

    settingsGet(leagueId) { return this.f("/api/Settings", "get", [{"name":"leagueId","in":"query"}], [leagueId]); }

    getTeam(teamId) { return this.f("/api/Team/GetTeam", "get", [{"name":"teamId","in":"query"}], [teamId]); }

    teamSetKeeper(teamId, leaguePlayerId) { return this.f("/api/Team/TeamSetKeeper", "get", [{"name":"teamId","in":"query"},{"name":"leaguePlayerId","in":"query"}], [teamId, leaguePlayerId]); }

    getTeamProfile(teamId) { return this.f("/api/Team/GetTeamProfile", "get", [{"name":"teamId","in":"query"}], [teamId]); }

}

export const client = new FantasyNflClient();