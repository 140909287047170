import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import PageNavbar from "../Navbars/PageNavbar.js";
import DataTable from "react-data-table-component";
import PageNavLinks from "../Navbars/PageNavLinks.js";

import { Container, Row, Col, Button } from "reactstrap";
import { client } from "../../services/FantasyNflClient.js";
import Spinner from "../Common/Spinner.js";
export function RosterBreakdown() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [adpValuesSelected, setAdpValuesSelected] = useState(true);
  let { leagueId } = useParams();

  useEffect(() => {
    client
      .getRosterBreakdown(leagueId)
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [leagueId]);

  const adpColumns = useMemo(
    () => [
      {
        name: "Team",
        selector: "teamName",
        sortable: true,
        width: "140px",
      },
      {
        name: "Players",
        selector: "count",
        sortable: true,
        center: true,
        width: "99px",
      },
      {
        name: "ADP",
        selector: "avgAdp",
        sortable: true,
        center: true,
        width: "99px",
        format: (row) => {
          return row.avgAdp.toFixed(2)
        },
      },
      {
        name: "QB",
        selector: "qbCount",
        sortable: true,
        center: true,
        width: "99px",
      },
      {
        name: "QB ADP",
        selector: "avgQbAdp",
        sortable: true,
        center: true,
        width: "99px",
        format: (row) => {
          return row.avgQbAdp.toFixed(2)
        },
      },
      {
        name: "RB",
        selector: "rbCount",
        sortable: true,
        center: true,
        width: "99px",
      },
      {
        name: "RB ADP",
        selector: "avgRbAdp",
        sortable: true,
        center: true,
        width: "99px",
        format: (row) => {
          return row.avgRbAdp.toFixed(2)
        },
      },
      {
        name: "WR",
        selector: "wrCount",
        sortable: true,
        center: true,
        width: "99px",
      },
      {
        name: "WR ADP",
        selector: "avgWrAdp",
        sortable: true,
        center: true,
        width: "99px",
        format: (row) => {
          return row.avgWrAdp.toFixed(2)
        },
      },
      {
        name: "TE",
        selector: "teCount",
        sortable: true,
        center: true,
        width: "99px",
      },
      {
        name: "TE ADP",
        selector: "avgTeAdp",
        sortable: true,
        center: true,
        width: "99px",
        format: (row) => {
          return row.avgTeAdp.toFixed(2)
        },
      },
    ],
    []
  );

  const ageColumns = useMemo(
    () => [
      {
        name: "Team",
        selector: "teamName",
        sortable: true,
        width: "140px",
      },
      {
        name: "Players",
        selector: "count",
        sortable: true,
        center: true,
        width: "99px",
      },
      {
        name: "Avg Age",
        selector: "avgAge",
        sortable: true,
        center: true,
        width: "99px",
        format: (row) => {
          return row.avgAge.toFixed(2);
        },
        conditionalCellStyles: [
          {
            when: (row) => row.avgAge < 24,
            style: {
              backgroundColor: "#73B520",
              color: "white",
            },
          },
          {
            when: (row) => row.avgAge >= 24 && row.avgAge < 25,
            style: {
              backgroundColor: "#EBC643",
              color: "white",
            },
          },
          {
            when: (row) => row.avgAge >= 25,
            style: {
              backgroundColor: "#EB5757",
              color: "white",
            },
          },
        ],
      },
      {
        name: "QB",
        selector: "qbCount",
        sortable: true,
        center: true,
        width: "99px",
      },
      {
        name: "QB Age",
        selector: "avgQbAge",
        sortable: true,
        center: true,
        width: "99px",
        format: (row) => {
          return row.avgQbAge.toFixed(2);
        },
      },
      {
        name: "RB",
        selector: "rbCount",
        sortable: true,
        center: true,
        width: "99px",
      },
      {
        name: "RB Age",
        selector: "avgRbAge",
        sortable: true,
        center: true,
        width: "99px",
        format: (row) => {
          return row.avgRbAge.toFixed(2);
        },
      },
      {
        name: "WR",
        selector: "wrCount",
        sortable: true,
        center: true,
        width: "99px",
      },
      {
        name: "WR Age",
        selector: "avgWrAge",
        sortable: true,
        center: true,
        width: "99px",
        format: (row) => {
          return row.avgWrAge.toFixed(2);
        },
      },
      {
        name: "TE",
        selector: "teCount",
        sortable: true,
        center: true,
        width: "99px",
      },
      {
        name: "TE Age",
        selector: "avgTeAge",
        sortable: true,
        center: true,
        width: "99px",
        format: (row) => {
          return row.avgTeAge.toFixed(2);
        },
      },
    ],
    []
  );

  const getColumns = () => {
    if (adpValuesSelected) {
      return adpColumns;
    } else {
      return ageColumns;
    }
  };

  return (
    <>
      <PageNavbar leagueId={leagueId} />
      {loading && <Spinner />}
      {!loading && (
        <>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="12">
                <div className="info">
                  <div className="description">
                    <h3 className="info-title">{data.leagueName}</h3>
                    <p className="mb-0">Roster Breakdowns</p>
                  </div>
                </div>
                <PageNavLinks leagueId={leagueId} />
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Button
                onClick={() => setAdpValuesSelected(true)}
                className="btn-round"
                color="info"
              >
                Adp
              </Button>
              <Button
                onClick={() => setAdpValuesSelected(false)}
                className="btn-round"
                color="danger"
              >
                Age
              </Button>
            </Row>
            <Row>
              <DataTable
                columns={getColumns()}
                dense={true}
                data={data.teams}
                striped={true}
                noHeader={true}
                defaultSortField={"positionSortOrder"}
                fixedHeader={true}
                pointerOnHover={true}
              />
            </Row>
          </Container>
        </>
      )}
    </>
  );
}
