import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import PageNavbar from "../Navbars/PageNavbar.js";
import DataTable from "react-data-table-component";
import PageNavLinks from "../Navbars/PageNavLinks.js";

import { Container, Row, Col } from "reactstrap";
import { client } from "../../services/FantasyNflClient.js";
import Spinner from "../Common/Spinner.js";

export function DepthChart() {
  const [loading, setLoading] = useState(true);
  const [depthChart, setDepthChart] = useState({});
  let { leagueId } = useParams();

  useEffect(() => {
    client
      .getDepthChart(leagueId)
      .then((data) => {
        setDepthChart(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [leagueId]);

  const columns = useMemo(
    () => [
      {
        name: "Team",
        selector: "teamName",
        sortable: false,
      },
      {
        name: "QB",
        selector: "quarterbacks",
        sortable: false,
        format: (row) => {
          return row.quarterbacks.map((p, index) => {
            return <div key={index}>{p.playerName}</div>;
          });
        },
      },
      {
        name: "RB",
        selector: "runningBacks",
        sortable: false,
        format: (row) => {
          return row.runningBacks.map((p, index) => {
            return <div key={index}>{p.playerName}</div>;
          });
        },
      },
      {
        name: "WR",
        selector: "wideReceivers",
        sortable: false,
        format: (row) => {
          return row.wideReceivers.map((p, index) => {
            return <div key={index}>{p.playerName}</div>;
          });
        },
      },
      {
        name: "TE",
        selector: "tightEnds",
        sortable: false,
        format: (row) => {
          return row.tightEnds.map((p, index) => {
            return <div key={index}>{p.playerName}</div>;
          });
        },
      },
      {
        name: "K",
        selector: "kickers",
        sortable: false,
        format: (row) => {
          return row.kickers.map((p, index) => {
            return <div key={index}>{p.playerName}</div>;
          });
        },
      },
      {
        name: "DEF",
        selector: "defenses",
        sortable: false,
        format: (row) => {
          return row.defenses.map((p, index) => {
            return <div key={index}>{p.playerName}</div>;
          });
        },
      },
    ],
    []
  );

  const customStyles = {
    rows: {
      style: {
        minHeight: "150px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  return (
    <>
      <PageNavbar leagueId={leagueId} />
      {loading && <Spinner />}
      {!loading && (
        <>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="12">
                <div className="info">
                  <div className="description">
                    <h3 className="info-title">{depthChart.leagueName}</h3>
                    <p className="mb-0">Depth Charts</p>
                  </div>
                </div>
                <PageNavLinks leagueId={leagueId} />
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto" md="12">
                <DataTable
                  columns={columns}
                  customStyles={customStyles}
                  data={depthChart.teamDepthCharts}
                  striped={true}
                  noHeader={true}
                  defaultSortField={"positionSortOrder"}
                  fixedHeader={true}
                  pointerOnHover={true}
                />
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
}
