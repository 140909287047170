import React, { useState, useEffect } from "react";
import { Division } from "./Division";
import { useParams } from "react-router-dom";
import PageNavbar from "../Navbars/PageNavbar.js";
import PageNavLinks from "../Navbars/PageNavLinks";
import { client } from "../../services/FantasyNflClient";
import { Container, Row, Col } from "reactstrap";
import Spinner from "../Common/Spinner";
import { FinalStandings } from "./FinalStandings";

const ActiveView = Object.freeze({
  listView: 0,
  regularSeasonView: 1,
  finalStandingsView: 2,
});

export function LeagueHistory() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [activeView, setActiveView] = useState(ActiveView.listView);
  const [selectedYear, setSelectedYear] = useState(0);  
  let { leagueId } = useParams();

  useEffect(() => {
    client
      .getLeagueYears(leagueId)
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [leagueId]);

  const getTeamByPosition = (teams, position) => {
    return teams.find((t) => t.finalPosition === position);
  };

  function groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      let v = key instanceof Function ? key(x) : x[key];
      let el = rv.find((r) => r && r.key === v);
      if (el) {
        el.values.push(x);
      } else {
        rv.push({ key: v, values: [x] });
      }
      return rv;
    }, []);
  }

  const getTeamsByDivision = () => {
    if (selectedYear > 0) {
      let teams = data.records.find((x) => x.year === selectedYear).teams;

      if (activeView == ActiveView.regularSeasonView) {
        return groupBy(teams, "division");
      }
     
      return groupBy(teams, "year");
    }    
  }

  const handleRegularSeason = (year) => {
    setSelectedYear(year) ;
    setActiveView(ActiveView.regularSeasonView); 
  }

  const handleFinalStandings = (year) => {
    setSelectedYear(year) ;
    setActiveView(ActiveView.finalStandingsView); 
  }

  const reset = () => {
    setSelectedYear(0) ;
    setActiveView(ActiveView.listView); 
  }

  return (
    <>
      <PageNavbar leagueId={leagueId} />
      {loading && <Spinner />}
      {!loading && (
        <>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="12">
                <div className="info">
                  <div className="description">
                    <h3 className="info-title">{data.leagueName}</h3>
                    <p className="mb-0">History</p>
                    {activeView === ActiveView.regularSeasonView && (
                      <p>{`Regular Season Standings ${selectedYear}`}</p>
                    )}
                    {activeView === ActiveView.finalStandingsView && (
                      <p>{`Final Standings ${selectedYear}`}</p>
                    )}
                  </div>
                </div>
                {activeView === ActiveView.listView && (
                  <PageNavLinks leagueId={leagueId} />
                )}                
                {activeView !== ActiveView.listView && (
                  <div style={{textAlign: "center"}}>
                  <button type="button" className="btn btn-neutral" onClick={() => reset()}>  
                  Back
                </button>
                </div>
                )}
              </Col>
            </Row>
            {activeView === ActiveView.finalStandingsView && (
              <Row>
              {getTeamsByDivision().map((division) => (
                <FinalStandings
                  key={division.key}
                  leagueId={leagueId}
                  divisionName={division.key}
                  teams={division.values}
                />
              ))}
            </Row>
            )}  
            {activeView === ActiveView.regularSeasonView && (
              <Row>
              {getTeamsByDivision().map((division) => (
                <Division
                  key={division.key}
                  leagueId={leagueId}
                  divisionName={division.key}
                  teams={division.values}
                />
              ))}
            </Row>
            )}            
            {activeView === ActiveView.listView &&
              data.records.map((leagueYear, index) => (
                <>
                  <div key={index} className="card card-profile ">
                    <div className="card-avatar border-white">
                      <a>
                        <img
                          height={40}
                          width={40}
                          src={getTeamByPosition(leagueYear.teams, 1)?.avatar}
                          alt="..."
                        />
                      </a>
                    </div>
                    <div className="card-body">
                      <h3 className={"mt-0"}>{leagueYear.year}</h3>
                      <h5>
                        1. {getTeamByPosition(leagueYear.teams, 1)?.teamName}
                      </h5>
                      <h5>
                        2. {getTeamByPosition(leagueYear.teams, 2)?.teamName}
                      </h5>
                      <h5>
                        3. {getTeamByPosition(leagueYear.teams, 3)?.teamName}
                      </h5>
                    </div>
                    <div className="card-footer">
                      <button type="button" className="btn btn-neutral" onClick={() => handleRegularSeason(leagueYear.year)}>
                        Regular Season
                      </button>
                      <button type="button" className="btn btn-neutral" onClick={() => handleFinalStandings(leagueYear.year)}>
                        Final Standings
                      </button>
                    </div>
                  </div>
                </>
              ))}
          </Container>
        </>
      )}
    </>
  );
}
