import React from "react";
import ReactPaginate from "react-paginate";
import pagination from "../../assets/theme/modules/_pagination.module.scss";

function Paginate({
  pageSize,
  maxPageSize,
  onChangePageSize,
  pageCount,
  onPageChange,
  initialPageSelected,
  hidePagination = false,
}) {
  return (
    <div
      hidden={hidePagination}
      className={`${pagination.pagination_wrapper} no-print mb-3`}
    >
      <select
        className="form-control form-control-sm page-size-select"
        value={pageSize}
        onChange={(e) => {
          onChangePageSize(e.target.value);
        }}
      >
        {[
          { key: 10, value: 10 },
          { key: 20, value: 20 },
          { key: 50, value: 50 },
          { key: 100, value: 100 },
          { key: "All", value: maxPageSize },
        ].map((pageSize) => (
          <option key={pageSize.key} value={pageSize.value}>
            Show {pageSize.key}
          </option>
        ))}
      </select>
      <nav>
        <ReactPaginate
          forcePage={initialPageSelected}
          previousLabel={""}
          nextLabel={""}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={(evnt) => {
            onPageChange(evnt.selected);
          }}
          containerClassName={`${pagination.pagination} justify-content-center`}
          activeClassName={`${pagination.active}`}
          disabledClassName={`${pagination.disabled}`}
          pageClassName={`${pagination.page_item}`}
          pageLinkClassName={`${pagination.page_link}`}
          previousClassName={`${pagination.page_item} ${pagination.previous}`}
          previousLinkClassName={`${pagination.page_link}`}
          nextClassName={`${pagination.page_item} ${pagination.next}`}
          nextLinkClassName={`${pagination.page_link}`}
          breakClassName={`${pagination.page_item}`}
          breakLinkClassName={`${pagination.page_link}`}
        />
      </nav>
    </div>
  );
}

export default Paginate;
