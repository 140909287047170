const locales = "en-GB";
const subtractMilliseconds = 3;

export const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d);
};

export const formatDateToString = (date) => {
  return isValidDate(new Date(date)) ? formatDate(date, "YYYY-MM-DD") : null;
};

export const formatDate = (dateText, format, invalidMessage = "-") => {
  if (!dateText) return invalidMessage;
  let date = dateText;

  if (!isValidDate(date)) {
    date = new Date(addTzd(dateText));

    if (!isValidDate(date)) return invalidMessage;
  }

  switch (format) {
    case "YYYY-MM-DD":
    case "YYYY-MM":
      return date.toISOString().substring(0, format.length);

    case "DD MMM YYYY":
      return date.toLocaleDateString(locales, {
        year: "numeric",
        month: "short",
        day: "2-digit",
      });

    case "DD MMMM YYYY":
      return date.toLocaleDateString(locales, {
        year: "numeric",
        month: "long",
        day: "2-digit",
      });

    case "DD MMM YY":
      return date.toLocaleDateString(locales, {
        year: "2-digit",
        month: "short",
        day: "2-digit",
      });

    case "DD/MM/YYYY":
      return date.toLocaleDateString(locales);

    case "HH:mm":
      return date.toLocaleTimeString(locales).substring(0, 5);

    case "HH:mm:ss":
      return date.toLocaleTimeString(locales).substring(0, 8);

    case "YYYY-MM-DD HH:mm":
    case "YYYY-MM-DD HH:mm:ss:SSS":
      return date
        .toISOString()
        .substring(0, format.length)
        .replace("T", " ")
        .replace(".", ":");

    case "MM/yyyy":
      return date.toLocaleDateString(locales, {
        year: "numeric",
        month: "2-digit",
      });

    default:
      return "-";
  }
};

// Add a time zone designator if there isn't one
function addTzd(dateString) {
  return typeof dateString === "string" &&
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/.test(dateString)
    ? dateString + "Z"
    : dateString;
}

export function addDays(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function addMonths(date, months) {
  const result = new Date(date);
  result.setMonth(result.getMonth() + months);
  return result;
}

export const getDifferenceInYears = (date1, date2) => {
  if (!isValidDate(date1) || !isValidDate(date2)) return new Date(null);

  return date2.getFullYear() - date1.getFullYear();
};

export const matchesMininumHours = (date, hours) => {
  if (!isValidDate(date)) return false;

  const current = new Date();
  const timeDiff =
    Math.abs(date.getTime() - current.getTime()) / (60 * 60 * 1000);

  return timeDiff <= hours ? true : false;
};

export const getDifferenceInMins = (date1, date2) => {
  if (!isValidDate(date1) || !isValidDate(date2)) return null;

  return Math.ceil(Math.round(date2 - date1) / (60 * 1000));
};

export const getDifferenceInDays = (date1, date2) => {
  if (!isValidDate(date1) || !isValidDate(date2)) return null;

  return Math.ceil(Math.round(date2 - date1) / (60 * 60 * 24 * 1000));
};

export const getFirstDateOfMonth = (date) => {
  if (!isValidDate(date)) return "-";
  return new Date(Date.UTC(date.getFullYear(), date.getMonth())).toISOString();
};

export const getLastDateOfMonth = (date) => {
  if (!isValidDate(date)) return "-";

  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth() + 1) - subtractMilliseconds
  ).toISOString();
};

export const getFirstDateOfWeek = (date) => {
  if (!isValidDate(date)) return "-";

  var day = date.getDay();
  var firstDay = date.getDate() - date.getDay() + (day === 0 ? -6 : 1);
  date.setDate(firstDay);
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  ).toISOString();
};

export const getLastDateOfWeek = (date) => {
  if (!isValidDate(date)) return "-";

  var firstDay = getFirstDateOfWeek(date);
  var lastday = addDays(firstDay, 7);

  return new Date(
    Date.UTC(lastday.getFullYear(), lastday.getMonth(), lastday.getDate()) -
      subtractMilliseconds
  ).toISOString();
};

export const getFirstDateOfYear = (date) => {
  if (!isValidDate(date)) return "-";

  return new Date(Date.UTC(date.getFullYear(), 0, 1)).toISOString();
};

export const getLastDateOfYear = (date) => {
  if (!isValidDate(date)) return "-";

  return new Date(
    Date.UTC(date.getFullYear() + 1, 0, 1) - subtractMilliseconds
  ).toISOString();
};

export const getFirstDateOfQuarter = (date) => {
  if (!isValidDate(date)) return "-";

  var monthOfQuarter = getMonthOfQuarter(date.getMonth() + 1);

  if (monthOfQuarter === 2) {
    date.setMonth(date.getMonth() - 1);
  } else if (monthOfQuarter === 3) {
    date.setMonth(date.getMonth() - 2);
  }

  return getFirstDateOfMonth(date);
};

export const getLastDateOfQuarter = (date) => {
  if (!isValidDate(date)) return "-";

  var monthOfQuarter = getMonthOfQuarter(date.getMonth() + 1);

  if (monthOfQuarter === 1) {
    date.setMonth(date.getMonth() + 2);
  } else if (monthOfQuarter === 2) {
    date.setMonth(date.getMonth() + 1);
  }

  return getLastDateOfMonth(date);
};

export const getFirstDateOfLastQuarter = (date) => {
  if (!isValidDate(date)) return "-";

  date = new Date(getFirstDateOfQuarter(date));
  date.setMonth(date.getMonth() - 3);
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  ).toISOString();
};

export const getLastDateOfLastQuarter = (date) => {
  if (!isValidDate(date)) return "-";

  date = new Date(getLastDateOfQuarter(date));
  date.setMonth(date.getMonth() - 3);
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) -
      subtractMilliseconds
  ).toISOString();
};

export const getStartOfDay = (date) => {
  if (!isValidDate(date)) return "-";
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  ).toISOString();
};

const getMonthOfQuarter = (monthOfYear) => {
  var result = monthOfYear % 3;
  return result === 0 ? 3 : result;
};

export const getEndOfDay = (date) => {
  if (!isValidDate(date)) return "-";

  date = addDays(date, 1);

  const subtractMilliseconds = 3;
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - subtractMilliseconds
  ).toISOString();
};
