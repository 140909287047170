import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
} from "reactstrap";
import { client } from "../../services/FantasyNflClient";
import { hasLeagueDrafted } from "../Common/LeagueSettings";

// core components

function HomeNavbar(props) {
  const [navbarColor, setNavbarColor] = useState("bg-black");
  const [bodyClick, setBodyClick] = useState(false);
  const [collapseOpen, setCollapseOpen] = useState(false);

  useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();

    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 499 ||
        document.body.scrollTop > 499
      ) {
        setNavbarColor("bg-danger");
      } else if (
        document.documentElement.scrollTop < 500 ||
        document.body.scrollTop < 500
      ) {
        setNavbarColor("bg-black");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar
        className={classnames("fixed-top", navbarColor)}
        id="navbar-main"
        expand="lg"
      >
        <Container>
          <div className="navbar-translate">
            <NavbarBrand id="navbar-brand" to="/" tag={Link}>
              FANTASY NFL
            </NavbarBrand>

            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setBodyClick(true);
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1"></span>
              <span className="navbar-toggler-bar bar2"></span>
              <span className="navbar-toggler-bar bar3"></span>
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle color="default" caret nav>
                  League
                </DropdownToggle>
                <DropdownMenu className="dropdown-danger">
                  <DropdownItem href={"/league/" + props.leagueId}>
                    Standings
                  </DropdownItem>
                  <DropdownItem href={"/depthchart/" + props.leagueId}>
                    Depth Chart
                  </DropdownItem>
                  {!hasLeagueDrafted() && (
                    <DropdownItem href={"/draftorder/" + props.leagueId}>
                      Draft Order
                    </DropdownItem>
                  )}

                  <DropdownItem href={"/rosterbreakdown/" + props.leagueId}>
                    Roster Breakdown
                  </DropdownItem>
                  {/*       <DropdownItem href={"/rosterbreakdown/" + props.leagueId}>*/}
                  {/*                                       Roster Breakdown*/}
                  {/*       </DropdownItem>*/}
                  {/*       <DropdownItem href={"/draftorder/" + props.leagueId}>*/}
                  {/*                                       Draft Order*/}
                  {/*       </DropdownItem>*/}
                  {/*                                   {!settings.availablePlayersHidden &&*/}
                  {/*(*/}
                  {/*    <DropdownItem href={"/availableplayers/" + props.leagueId}>*/}
                  {/*                                           Available Players*/}
                  {/*                                       </DropdownItem>*/}

                  <DropdownItem href={"/rookies/" + props.leagueId + "/2024"}>
                    2024 Rookies
                  </DropdownItem>
                  <DropdownItem
                    href={"/history/" + props.leagueId + "/pointsbyyear/2024"}
                  >
                    2024 Team Scores
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default HomeNavbar;
