import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import PageNavbar from "../Navbars/PageNavbar.js";
import PageNavLinks from "../Navbars/PageNavLinks.js";
import DataTable from "react-data-table-component";

import { Container, Row, Col } from "reactstrap";
import { client } from "../../services/FantasyNflClient.js";
import Spinner from "../Common/Spinner.js";

export function PointsByYearTeamWeek() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  let { leagueId, year, teamId, week } = useParams();

  useEffect(() => {
    client
      .pointsByYearTeamWeek(leagueId, year, teamId, week)
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [leagueId, year, teamId]);

  const columns = useMemo(
    () => [
      {
        name: "Position",
        selector: "index",
        sortable: true,
        format: (row) => {
          return row.playerPosition;
        },
      },
      {
        name: "Player Name",
        selector: "playerName",
        sortable: true,
      },
      {
        name: "Score",
        selector: "score",
        sortable: true,
      },
    ],
    []
  );

  return (
    <>
      <PageNavbar leagueId={leagueId} />
      {loading && <Spinner />}
      {!loading && (
        <>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="12">
                <div className="info">
                  <div className="description">
                    <h3 className="info-title">
                      {data.details[0]?.teamName}
                    </h3>
                    <p className="mb-0">
                      {year} Week {week} Scores
                    </p>
                  </div>
                </div>
                <PageNavLinks leagueId={leagueId} />
              </Col>
            </Row>
            <Row>
              <DataTable
                columns={columns}
                dense={true}
                data={data.details}
                striped={true}
                defaultSortField={"index"}
                noHeader={false}
                fixedHeader={true}
                pointerOnHover={true}
              />
            </Row>
          </Container>
        </>
      )}
    </>
  );
}
